// 渠道码
export const channelMap = {
  MP: '小程序',
  OAA: 'D-MAX App Android',
  OAI: 'D-MAX App iOS',
  SAW: '销售助手Web',
  SAA: '销售助手App Android',
  SAI: '销售助手App iOS',
  CS: '客户满意中心',
  EIS: '私桩安装',
  FCMP: '锋巢小程序',
  OH5: 'D-MAX纯H5页面',
  PAY: '支付中心',
  URM: 'URM',
  ISP: 'ISP',
  OWS: '官网',
  DHW: '交付中心Web',
  DHA: '交付中心App Android',
  DHI: '交付中心App iOS',
  TSP: 'TSP'
}
// 订单状态
export const orderStatusAttr = {
  '00': 'Pending for Booking Fee',
  '01': 'Cancelled',
  '02': 'Booking Fee Paid',
  '03': 'Refunding',
  '04': 'Failed to Refund',
  '05': 'Closed',
  '06': 'Expired',
  '10': 'Pending for Deposit',
  '20': 'Deposit Paid',
  '21': 'Order Confirmed',
  '22': 'Pending for final payment',
  '23': 'Pending for downpayment and loan',
  '24': 'Downpayment paid pending for loan',
  '30': 'Pending for delivery',
  '99': 'Completed'
}
// 交付状态
export const deliveryStatusAttr = {
  '100': '未销售确认',
  '00': '已确认',
  '10': '正在安排生产',
  '11': '生产中',
  '12': '生产下线',
  '13': '待发运',
  '20': '已做发运指令',
  '21': '发运指令取消中',
  '22': 'VDC已出库',
  '23': '车辆到达DH',
  '30': '车辆入库',
  '31': '待验车',
  '32': '待客户确认验车',
  '33': '已验车',
  '40': '待提车',
  '45': '待客户确认提车',
  '50': '车辆待交付',
  '60': '交付异常',
  '99': '已交付'
}
// 证件类型
export const cardTypeAttr = [
  { value: '1', label: 'MyKad' },
  { value: '2', label: '护照' },
  { value: '3', label: 'MyPR' },
  { value: '4', label: 'Passport' },
  { value: '5', label: '台胞证' },
  { value: '6', label: '统一社会信用代码' }
]

export const cardTypeMap = {
  '1': 'MyKad',
  '2': '护照',
  '3': 'MyPR',
  '4': 'Passport',
  '5': '台胞证',
  '6': '统一社会信用代码'
}

// 勘测单状态
export const surveyStatusAttr = [
  { value: '1', label: '待勘察' },
  { value: '2', label: '勘察中' },
  { value: '3', label: '勘察待监理' },
  { value: '4', label: '勘察完成' },
  { value: '5', label: '安装准备' },
  { value: '6', label: '待安装' },
  { value: '7', label: '安装中' },
  { value: '8', label: '安装待监理' },
  { value: '9', label: '安装完成' },
  { value: '10', label: '勘察中止' },
  { value: '11', label: '安装中止' }
]

export const surveyStatusMap = {
  1: '待勘察',
  2: '勘察中',
  3: '勘察待监理',
  4: '勘察完成',
  5: '安装准备',
  6: '待安装',
  7: '安装中',
  8: '安装待监理',
  9: '安装完成',
  10: '勘察中止',
  11: '安装中止'
}

// 合同状态
export const orderContractStatusMap = {
  0: '未签署合同',
  1: '合同签署中',
  2: '已签署合同'
}
