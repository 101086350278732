/*
 * @Author: your name
 * @Date: 2021-05-28 11:02:09
 * @LastEditTime: 2021-06-05 10:56:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \04eos-app\src\pages\customerInfo\attr.js
 */
// 线索状态
export const clueStatus = {
  // '4': '活动',
  'OPEN': 'Open',
  'ATTEMPTING_TOUCHING': ' To contact',
  'CLOSE_NO_TOUCHING': 'Failed to contact',
  'IN_TOUCHING': 'Following',
  'TD_SCHEDULED': 'Test drive scheduled',
  'CLOSELOST': 'Close-Lost',
  'CONVERTED': 'Converted',
  'TD': 'TD',
  //'OPEN': 'Open',
  'FOLLOW_UP': 'Follow Up',
  'LONG_TERM': 'Long Term',
  // 'CLOSE_DUPLICATE': '重复关闭',
  'DEPOSIT_RESERVED': 'Deposit Reserved',
  'CLOSE_LOST': 'Close-Lost',
  // 'ORDER': '大定',
  // 'CLOSE_CANCELLED': '退订'
}

// 商机状态处理
export const oppStatus = {
  'TD': 'TD',
  'OPEN': 'Open',
  'FOLLOW_UP': 'Follow Up',
  'LONG_TERM': 'Long Term',
  'CLOSE_DUPLICATE': 'Close-Duplicate',
  'DEPOSIT_RESERVED': 'Deposit Reserved',
  'CLOSE_LOST': 'Close-Lost',
  // 'DELIVERED': '已交付',
  // 'ORDER': '大定',
  // 'CLOSE_CANCELLED': '退订'
}

// 试驾状态
export const testdriveStatus = {
  'SCHEDULED': '已排程',
  'CONFIRMED': '已确认',
  'CANCELLED': '已取消',
  'COMPLETED': '已完成',
  'OVERDUE': '已逾期'
}
// 活动状态
export const activeStatus = {
  'ATTEMPTING_TOUCHING': 'To contact',
  'CLOSE_NO_TOUCHING':  '无法联系',
  'IN_TOUCHING': 'Following',
  'TD_SCHEDULED': '已预约试驾',
  'CLOSELOST': '暂败',
  'CONVERTED': '已转化'
}
// 勘测单状态
export const surveyStatusAttr = {
  '1': '待勘察',
  '2': '勘察中',
  '3': '勘察待监理',
  '4': '勘察完成',
  '5': '安装准备',
  '6': '待安装',
  '7': '安装中',
  '8': '安装待监理',
  '9': '安装完成',
  '10': '勘察中止',
  '11': '安装中止'
}