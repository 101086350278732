<template>
  <!-- <App title="跟进记录" left-arrow @click-left="onNavBarClickLeft" @click-right="c">
    <template #right>
      <Icon name="records" size="18" />
    </template> -->
  <div class="cp-box">
    <!-- <div
      class="header"
      :style="headerStyle"
    >
      <div>
        <Icon
          name="arrow-left"
          size="18"
          @click="onNavBarClickLeft"
        />
      </div>
      <div>
        <span
          v-auth="'CustomerDetails-Edit'"
          name="records"
          style="color:#DA3738; font-size: 14px;"
        >编辑 </span>
      </div>
    </div> -->
    <CellGroup>
      <Cell
        title="Follow-up History"
        :value="paginate.total"
      />
    </CellGroup>
    <PullRefresh
      v-show="hasDate"
      v-model="loading"
      @refresh="refresh"
      style="margin-bottom: 72px; background: #fff;"
    >
      <List
        v-model="loading"
        :finished="finished"
        @load="getData"
        finished-text="No more items."
        class="getDataList"
        offset="600"
      >
        <Steps
          direction="vertical"
          :active="0"
          class="timeline"
          active-icon=""
          active-color="#DA3738"
        >
          <Step
            v-for="($e,$i) in pageData"
            :key="$i"
          >
            <div
              slot="active-icon"
              class="active-icon"
            ></div>
            <div class="left">
              <div class="date">{{$e.date}}</div>
              <div class="time">{{$e.time}}</div>
            </div>
            <div
              class="right"
              @click="followUpInfo($e)"
            >
              <div class="status">
                <span>{{$e.statusTxt}}</span>
                <Icon
                  name="arrow"
                  size="15"
                  style="align-self: center;"
                />
              </div>
              <div class="counselor">{{$e.counselor}}</div>
              <div class="type">{{$e.fromText}}</div>
              <div class="remark">{{$e.followupMessage}}</div>
            </div>
          </Step>
        </Steps>
      </List>
    </PullRefresh>
    <div style="display:flex;justify-content: center; left: 0; bottom: 0; position: fixed; width: 100%; padding: 20px 0; background: #fff; z-index: 200;">
      <Button
        round
        plain
        type="primary"
        size="small"
        @click="refresh"
      >Refresh</Button>
    </div>
  </div>

  <!-- </App> -->
</template>

<script>
import { PullRefresh, List, Steps, Step, Icon, Button, Cell, CellGroup, Toast } from 'vant';
import { oppfollowupList, leadsfollowuplist } from '@/api/clue/index'
import { clueStatus } from '../../attr'
import './index.less';
import dayjs from 'dayjs'
export default {
  components: {
    PullRefresh,
    List,
    Steps,
    Step,
    Icon,
    Button,
    Cell,
    CellGroup
  },
  props: {
    type: {
      type: String,
      default: 'leads',
      require: true
    },
    id: {
      type: String,
      require: true
    },
    customerInfo: {
      type: Object,
      require: true
    }
  },
  watch: {
    id: {
      immediate: true,
      handler () {
        this.paginate.page = 1
        this.init()
        this.finished = false
        this.pageData = []
      }
    }
  },
  data () {
    return {
      hasDate: true,
      loading: true,
      finished: false,
      paginate: {
        page: 1,
        size: 6,
        total: 1
      },
      pageData: []
    }
  },
  computed: {
    headerStyle () {
      return {
        'background-color': '#fff'
      }
    },
  },
  methods: {
    onNavBarClickLeft () {
      this.$emit('hideRecord')
    },
    followUpInfo (e) {
      this.$router.push({ path: '/followUpInfo', query: { e } })
    },
    init () {
      this.getData()
    },
    // 跟进记录中转化跟进形式
    changeFollowFrom (item) {
      let type = ''
      this.$storage.get('baseData')['follow_from'].forEach(el => {
        if (el.value === item) {
          type = el.key
        }
      })
      return type
    },
    // 线索/商机跟进记录
    async getData () {
      let res = {}
      /* Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      }) */
      try {
        if (this.type === 'leads') {
          res = await leadsfollowuplist(
            {
              page: this.paginate.page,
              size: this.paginate.size
            },
            {
              leadsoppId: this.id
            }
          )
        } else {
          res = await oppfollowupList({
            page: this.paginate.page,
            size: this.paginate.size
          },
            {
              leadsoppId: this.id
            })
        }
        Toast.clear()
        if (res.success) {
          this.paginate.total = res.data.total
          this.loading = false
          if (res.data.records.length < 1) {
            this.finished = true
            return false
          }
          // 跟进形式做映射
          const list = res.data.records.map(item => {
            return {
              id: item.eosLeadsId, // 不确定
              date: item.createtime ? dayjs(item.createtime).format('MM/DD') : '',
              time: item.createtime ? dayjs(item.createtime).format('HH:mm') : '',
              statusTxt: clueStatus[item.status],
              fromText: this.changeFollowFrom(item.followupForm), // 跟进形式
              counselor: item.ownerUserName,
              followpId: item.eosFollowupId,
              name: this.customerInfo.name,
              phone: this.customerInfo.phone,
              ...item
            }
          })
          const arr = [...this.pageData, ...list]
          this.pageData = this.arrDistinctByProp(arr, 'eosFollowupId')
          if (this.paginate.total !== this.pageData.lenght) {
            this.paginate.page = this.paginate.page + 1
          }
        } else {
          Toast('请求数据失败')
        }
      } catch (error) {
        // console.log(error)
      }
    },
    arrDistinctByProp (arr, prop) {
      return arr.filter(function (item, index, self) {
        return self.findIndex(el => el[prop] == item[prop]) === index
      })
    },
    onNavBarClickRight () {
      this.$emit('editAccount')
    },
    refresh () {
      this.paginate.page = 1
      this.finished = false
      this.pageData = []
      this.getData()
    }
  }
}
</script>