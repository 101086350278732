<!--
 * @Author: your name
 * @Date: 2021-05-30 18:45:51
 * @LastEditTime: 2021-11-08 17:43:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \04eos-app\src\components\card\cardCell.vue
-->
<template>
  <div class="card-cell" :style="style">
    <div :class="['label', { 'colon': colon }]" :style="labelStyle">
      <slot name="label">{{ label }}</slot>
    </div>
    <div class="value" style="color:#DA3738;position:relative;">
      <slot name="value">{{ value }}</slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: [String, Number],
      default: 'auto'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    justify: {
      type: String,
      default: 'flex-start'
    },
    colon: { // 是否在label后面加分号
      type: Boolean,
      default: true
    }
  },
  computed: {
    style() {
      return {
        'justify-content': this.justify
      }
    },
    labelStyle() {
      return {
        'width': this.labelWidth
      }
    }
  }
}
</script>
<style lang="less" scoped>
.card-cell {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #666;
  padding-bottom: 12px;
  .label {
    &.colon::after {
      content: ':';
      font-size: 24px;
      white-space: nowrap;
    }
    margin-right: 24px;
    text-align: right;
  }
}
</style>