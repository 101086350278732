const ua = navigator.userAgent

const REG_ANDROID = /\bAndroid ([\d.]+)/
const REG_IOS = /\b(iPad|iPhone|iPod)\b.*? OS ([\d_]+)/
const isAndroid = REG_ANDROID.test(ua) //安卓
const isIOS = !isAndroid && REG_IOS.test(ua) //ios
var bridge = window.WebViewJavascriptBridge

const tokenConfig = {
  interval: 50,
  maxCount: 50
}
if (localStorage.getItem('token')) {
  tokenConfig.maxCount = 1
}
export default {
  getUserToken(flag = false) {
    if (flag) {
    return new Promise((res, rej) => {
      try {
        window.WebViewJavascriptBridge.callHandler('GetUserToken', { key: 'JSValue' }, (response) => {
          let token = response.result;
          console.log('get user token ...', token)
          // const token  = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyVHlwZSI6bnVsbCwiaWQiOiIxNjEwOTM3Nzk0NTcwNTU5NDg5IiwiZXhwIjoxNjc1NTA0NTAwLCJpYXQiOjE2NzI5MTI1MDAsInVzZXJuYW1lIjoiIn0.4evyZH4LtIdf-CCafKjC5PdNOTG-dvPrTejP89t8VyU'
          localStorage.setItem('token', token)
          res(token);
        });
      } catch (error) {
        console.log('getUserToken err', error)
        rej(error)
      }
    })
    }
    return new Promise((res, rej) => {
      let i = 0,
        timer = null,
        interval = 100,
        maxCount = 100
      const getToken = () => {
        console.log('尝试获取token', i)
        try {
          window.WebViewJavascriptBridge.callHandler(
            'GetUserToken',
            { key: 'JSValue' },
            (response) => {
              let token = response.result
              console.log('get user token ...', token)
              // const token  = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyVHlwZSI6bnVsbCwiaWQiOiIxNjEwOTM3Nzk0NTcwNTU5NDg5IiwiZXhwIjoxNjc1NTA0NTAwLCJpYXQiOjE2NzI5MTI1MDAsInVzZXJuYW1lIjoiIn0.4evyZH4LtIdf-CCafKjC5PdNOTG-dvPrTejP89t8VyU'
              localStorage.setItem('token', token)
              res(token)
            }
          )
        } catch (error) {
          console.log('尝试获取token失败', i)
          console.log('getUserToken err', error)
          i++
          if (i < tokenConfig.maxCount) {
            timer = setTimeout(() => {
              getToken()
            }, tokenConfig.interval)
          } else {
            rej(error)
          }
        }
      }
      getToken()
      // setTimeout(() => {
      //   try {
      //     window.WebViewJavascriptBridge.callHandler(
      //       'GetUserToken',
      //       { key: 'JSValue' },
      //       (response) => {
      //         let token = response.result
      //         console.log('get user token ...', token)
      //         // const token  = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyVHlwZSI6bnVsbCwiaWQiOiIxNjEwOTM3Nzk0NTcwNTU5NDg5IiwiZXhwIjoxNjc1NTA0NTAwLCJpYXQiOjE2NzI5MTI1MDAsInVzZXJuYW1lIjoiIn0.4evyZH4LtIdf-CCafKjC5PdNOTG-dvPrTejP89t8VyU'
      //         localStorage.setItem('token', token)
      //         res(token)
      //       }
      //     )
      //   } catch (error) {
      //     console.log('getUserToken err', error)
      //     rej(error)
      //   }
      // }, 100)
    })
  },
  getUserInfo(flag = false) {

    if (flag) {
      
    return new Promise((res, rej) => {
      try {
        window.WebViewJavascriptBridge.callHandler('GetUserInfo', { key: 'JSValue' }, (response) => {
          const userInfo = response.result || JSON.stringify({})
          localStorage.setItem('userInfo', userInfo)
          console.log('response ...', response)
          console.log('response typeof ...', typeof response)
          console.log('response userInfo ...', userInfo)
          console.log('response userInfo typeof ...', typeof userInfo)
          // console.log('get user info ...', userInfo, typeof response, typeof userInfo)
          res(userInfo);
        });

      } catch (error) {
        console.log('getUserInfo err', error)
        rej(error)
      }

    })
    }
    return new Promise((res, rej) => {
      let i = 0,
        timer = null

      const getInfo = () => {
        try {
          window.WebViewJavascriptBridge.callHandler(
            'GetUserInfo',
            { key: 'JSValue' },
            (response) => {
              const userInfo = response.result || JSON.stringify({})
              localStorage.setItem('userInfo', userInfo)
              console.log('response ...', response)
              console.log('response typeof ...', typeof response)
              console.log('response userInfo ...', userInfo)
              console.log('response userInfo typeof ...', typeof userInfo)
              // console.log('get user info ...', userInfo, typeof response, typeof userInfo)
              res(userInfo)
            }
          )
        } catch (error) {
          console.log('getUserToken err', error)
          i++
          clearTimeout(timer)
          if (i < tokenConfig.maxCount) {
            timer = setTimeout(() => {
              getInfo()
            }, tokenConfig.interval)
          } else {
            rej(error)
          }
        }
      }
      getInfo()

      // setTimeout(() => {
      //   try {
      //     window.WebViewJavascriptBridge.callHandler(
      //       'GetUserInfo',
      //       { key: 'JSValue' },
      //       (response) => {
      //         const userInfo = response.result || JSON.stringify({})
      //         localStorage.setItem('userInfo', userInfo)
      //         console.log('response ...', response)
      //         console.log('response typeof ...', typeof response)
      //         console.log('response userInfo ...', userInfo)
      //         console.log('response userInfo typeof ...', typeof userInfo)
      //         // console.log('get user info ...', userInfo, typeof response, typeof userInfo)
      //         res(userInfo)
      //       }
      //     )
      //   } catch (error) {
      //     console.log('getUserInfo err', error)
      //   }
      // }, 100)
    })
  },
  saveImage(data) {
    return new Promise((res, rej) => {
      try {
        window.WebViewJavascriptBridge.callHandler('SaveImage', { ...data }, (response) => {
          console.log('SaveImage ...', data)
          res(response)
        })
      } catch (error) {
        console.log('saveImage err', error)
      }
    })
  },
  nativeRouter(data) {
    return new Promise((res, rej) => {
      try {
        // { hostAndPath: 'user/personal', userId: 1615607718131867650 }
        window.WebViewJavascriptBridge.callHandler('Router', { ...data }, (response) => {
          console.log('nativeRouter ...', data)
          res(response)
        })
      } catch (error) {
        console.log('nativeRouter err', error)
      }
    })
  },
  login() {
    console.log('login ...')
    return new Promise((res, rej) => {
      try {
        window.WebViewJavascriptBridge.callHandler('Login', {}, (response) => {
          console.log('login ...', response)
          res(response)
        })
      } catch (error) {
        rej(error)
        console.log('login err', error)
      }
    })
  },
  logout() {
    console.log('Logout ...')
    return new Promise((res, rej) => {
      try {
        window.WebViewJavascriptBridge.callHandler('Logout', {}, (response) => {
          console.log('Logout ...', response)
          res(response)
        })
      } catch (error) {
        rej(error)
        console.log('Logout err', error)
      }
    })
  },
  share(link) {
    console.log('share ...')
    return new Promise((res, rej) => {
      try {
        window.WebViewJavascriptBridge.callHandler('ShareTo', { content: link }, (response) => {
          console.log('share ...', response)
          res(response)
        })
      } catch (error) {
        rej(error)
        console.log('share err', error)
      }
    })
  },
  chat(phone) {
    console.log('chat ...')
    return new Promise((res, rej) => {
      try {
        window.WebViewJavascriptBridge.callHandler('ChatTo', { mobile: `+86${phone}` }, (response) => {
          console.log('chat ...', response)
          res(response)
        })
      } catch (error) {
        rej(error)
        console.log('chat err', error)
      }
    })
  },
  isLogin() {
    const token = localStorage.getItem('token')
    console.log('校验是否登陆', token)
    if (token) {
      return true
    }
    return false
  },
  doClosePage() {
    return new Promise((res, rej) => {
      try {
        console.log(
          window.WebViewJavascriptBridge,
          'window.WebViewJavascriptBridge-------------------'
        )
        window.WebViewJavascriptBridge.callHandler('DoClosePage', {}, (response) => {
          console.log('doClosePage success ...', response)
          res(response)
        })
      } catch (error) {
        rej(error)
        console.log('doClosePage err ...', error)
      }
    })
  },
  appPay(data, callback) {
    window.WebViewJavascriptBridge.callHandler('AppPay', { ...data }, (response) => {
      console.log('appPay ...', response)
      callback && callback(response)
    })
  },
  quickEntryPage(linkType) {
    return new Promise((res, rej) => {
      try {
        window.WebViewJavascriptBridge.callHandler('QuickEntryPage', { linkType }, (response) => {
          console.log('QuickEntryPage ...', response)
          res(response)
        })
      } catch (error) {
        rej(error)
      }
    })
  },
  uploadImage() {
    return new Promise((res, rej) => {
      // res({
      //   result: [
      //     'https://smart-uat.obs.my-kualalumpur-1.alphaedge.tmone.com.my/smart/monments/16928858240284180491698747710351.jpg'
      //   ]
      // })
      // return
      try {
        window.WebViewJavascriptBridge.callHandler('UploadImage', {}, (response) => {
          console.log('uploadImage ...', response)
          res(response)
        })
      } catch (error) {
        console.log('uploadImage ... error ', error)
        rej(error)
      }
    })
  },
  ImagePickCount(count) {
    return new Promise((res, rej) => {
      // res({ result: 'OK' })
      // return
      try {
        window.WebViewJavascriptBridge.callHandler('ImagePickCount', { count }, (response) => {
          console.log('ImagePickCount ...', response)
          res(response)
        })
      } catch (error) {
        console.log('ImagePickCount ...', error)
        rej(error)
        
      }
    })
  },
  getAppVersion() {
    return new Promise((res, rej) => {
      try {
        window.WebViewJavascriptBridge.callHandler('GetAppVersion', {  }, (response) => {
          console.log('GetAppVersion ...', response)
          res(response)
        })
      } catch (error) {
        console.log('GetAppVersion ...', error)
        rej(error)
      }
    })
  },
  searchPOI() {
    return new Promise((res, rej) => {
      try {
        window.WebViewJavascriptBridge.callHandler('SearchPOI', {  }, (response) => {
          console.log('GetAppVersion ...', response)
          res(response)
        })
      } catch (error) {
        console.log('GetAppVersion ...', error)
        rej(error)
      }
    })
  },
  showPOI(data = {}) {
    return new Promise((res, rej) => {
      try {
        window.WebViewJavascriptBridge.callHandler('ShowPOI', { ...data }, (response) => {
          console.log('GetAppVersion ...', response)
          res(response)
        })
      } catch (error) {
        console.log('GetAppVersion ...', error)
        rej(error)
      }
    })
  },
  commonWeb(url) {
    return new Promise((res, rej) => {
      try {
        window.WebViewJavascriptBridge.callHandler('Router', { 
          hostAndPath: 'common/web', url: url, showToolbar: 'false'
         }, (response) => {
          console.log('GetAppVersion ...', response)
          res(response)
        })
      } catch (error) {
        console.log('GetAppVersion ...', error)
        rej(error)
      }
    })
  },
}
