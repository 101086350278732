/*
 * @Author: your name
 * @Date: 2021-05-27 17:13:09
 * @LastEditTime: 2021-06-07 14:07:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \04eos-app\src\api\general\index.js
 */
import request from '@/utils/request'
import Api from '@/api/base/api-config'

//获取通用状态accountinfo
export const accountinfo = params => {
  return request({
    method: 'get',
    url: `${Api.general.accountinfo}?id=${params.id}`
  })
}
